export class LoginManager {
    constructor() {
        this.user = null;
        this.hooks = [];
    }

    async fetchUser() {
        const response = await fetch("api/identity/getcurrentuser",
            {
                method: "GET"
            });
        this.user = null;
        if (response.status === 200) {
            const data = await response.text();
            if (data) {
                try {
                    this.user = JSON.parse(data);
                } catch (e) {
                    console.log(`Can't parse user info: ${e}`);
                }
            }
        }
        this.callHooks();
    }

    isLoggedIn() {
        return (this.user !== null);
    }

    subscribe(func) {
        this.hooks.push(func);
    }

    callHooks() {
        this.hooks.forEach((f) => f(this.isLoggedIn()));
    }
}