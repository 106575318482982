import React, {Fragment, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {HashLink as Link} from "react-router-hash-link";

export function RegistrationForm(props) {
    useEffect(() => {
        document.title = `Register | ${document.title}`;
    }, []);
    
    let headerMessageDom = <Fragment/>;
    if (props.origin === "checkout") {
        headerMessageDom = <h5 className="mb-5">You need to create an account to complete your order:</h5>
    }

    if (!props.formSent) {
        return (
            <Fragment>
            {headerMessageDom}
            <div className="card border-soundspear-black rounded-0">
                <div className="card-header p-0">
                    <div className="bg-black text-soundspear-primary text-center py-2">
                        <h3><FontAwesomeIcon icon={["fas", "envelope"]
} size="sm" className="mt-3"/> Create an account</h3>
                        <p className="m-0"></p>
                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={[
                                    "fas", "envelope"
                                ]} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="email" type="text" className="form-control" placeholder="Your email..." required
                                   onChange={(t) => { props.onFormUpdate(t); }}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={[
                                    "fas", "key"
                                ]} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="password" type="password" className="form-control" required placeholder="Your password..."
                                   onChange={(t) => { props.onFormUpdate(t); }}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={[
                                    "fas", "key"
                                ]} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="confirmPassword" type="password" className="form-control" cols="60" rows="6" required placeholder="Confirm your password..."
                                   onChange={(t) => { props.onFormUpdate(t); }}/>
                        </div>
                    </div>

                    <div className="text-center">
                        <input type="submit" value="Send" className="btn btn-soundspear-primary-black btn-block py-2"/>
                    </div>
                </div>
            </div>
            <h6 className="mt-3">Already have an account? <Link to="/login">Click here to log in.</Link></h6>
            </Fragment>
        );
    } else {
        return (
            <div className="alert alert-warning" role="alert">
                Your account was successfully created. You must activate it by clicking the link which was sent to you by email..
            </div>);
    }
}