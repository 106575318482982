import React, { useEffect } from "react";

import { Redirect, Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function LoginForm(props) {
    useEffect(() => {
        document.title = `Log In | Soundspear - High end digital sound processing - VST - AU`;
    }, []);
    if (props.formSent) {
        props.loginManager.fetchUser();
        return (<Redirect push to="/"/>);
    }
    return (
        <div className="card border-soundspear-black rounded-0">
            <div className="card-header p-0">
                <div className="bg-black text-soundspear-primary text-center py-2">
                    <h3><FontAwesomeIcon icon={["fas", "envelope"]
    } size="sm" className="mt-3"/> Log In</h3>
                    <p className="m-0"></p>
                </div>
            </div>
            <div className="card-body p-3">
                <div className="form-group">
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={[
                                    "fas", "envelope"
                                ]} size="sm" className="form-icon"/>
                            </div>
                        </div>
                        <input name="email" type="text" className="form-control" placeholder="Your email..." required
                               onChange={(t) => { props.onFormUpdate(t); }}/>
                    </div>
                </div>

                <div className="form-group">
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={[
                                    "fas", "key"
                                ]} size="sm" className="form-icon"/>
                            </div>
                        </div>
                        <input name="password" type="password" className="form-control" required placeholder="Your password..."
                               onChange={(t) => { props.onFormUpdate(t); }}/>
                    </div>
                </div>


                <div className="form-check mb-3">
                    <input name="rememberMe" type="checkbox" className="form-check-input" id="exampleCheck1"/>
                    <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                </div>

                <div className="text-center">
                    <input type="submit" value="Send" className="btn btn-soundspear-primary-black btn-block py-2"/>
                </div>

                <Link to="forgotpassword">Forgot your password?</Link>
            </div>
        </div>
    );
}