import React, {Component, Fragment} from "react";
import {Container} from "reactstrap";
import {Helmet} from "react-helmet";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import "../../style/blog.scss";
import BounceLoader from "react-spinners/BounceLoader";

export class BlogPost extends Component {
    static displayName = BlogPost.name;

    constructor(props) {
        super(props);
        this.state = { content: null };
    }
    async componentDidMount() {
        try {
            const urlElements = window.location.href.split("/");
            const resp = await fetch(`/api/Blog/GetPost?url=${this.currentArticlePermalink}`);
            const parsed = await resp.json();
            this.setState({ content: parsed });
        } catch (err) {
            console.error(`Can't load blog articles: ${err}`);
            this.setState({ content: null });
        }
    }
    
    async componentWillUnmount() {
        this.setState({ content: null });
    }
    
    get currentArticlePermalink() {
        return this.props.match.params.postPermalink;
    }
    
    get headContent() {
        if (!this.state.content) {
            return <Fragment/>;
        }
        
        return <Helmet>
            <title>{`${this.state.content.title} | Soundspear Blog - VST - AU`}</title>
            <meta name="description" content={this.state.content.description} />
            <meta property="og:site_name" content="Soundspear Plug-Ins"/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={`https://soundspear.com/blog/${this.state.content.url}`}/>
            <meta property="og:image" content={`https://soundspear.com${this.state.content.thumbnailUrl}`}/>
        </Helmet>;
    }
    
    renderArticle() {
        if (!this.state.content) {
            return <BounceLoader
                size={75}
                color={"#d4af37"}/>;
        }

        return (
            <ReactMarkdown escapeHtml={false} allowDangerousHtml={true} rehypePlugins={rehypeRaw}>
                {this.state.content.contentMarkdown}
            </ReactMarkdown>
        );
    }
    
    render() {
        return (
            <Fragment>
                {this.headContent}
                <div className="formula-body">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Blog - Soundspear - VST - VST3 - AU</title>
                        <meta name="description" content="Bundling innovation into premium VST plugins by combining cutting-edge algorithms and traditional hardware emulation" />
                    </Helmet>

                    <Container className="product-page-container">
                        <div className="blog-post-content">
                            {this.renderArticle()}
                        </div>
                    </Container>
                </div>
            </Fragment>
        );
    }
}