import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { HashLink as Link } from 'react-router-hash-link';
import Carousel from "react-spring-3d-carousel";
import { Helmet } from "react-helmet";

import "../../style/home.scss";
import {RecommendedPosts} from "../layout/RecommendedPosts";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        const data = this.props.products.filter(p => p.isOnHomePage);

        const products = data.map((product, index) => {
            return {
                ...product,
                key: index
            };
        });

        const slides = data.map((product, index) => {
            return {
                key: index,
                content: <img src={product.imageUri} alt={product.name} key={index}
                    className="carousel-product-image border-soundspear-primary"
                    onClick={() => {
                        if (this.state.goToSlide === index) {
                            this.props.history.push(`/product/${product.permalink}`);
                        } else {
                            this.setState({
                                goToSlide: index
                            });
                        }
                    }} />
            };
        });

        this.state = {
            currentSlide: 0,
            goToSlide: 0,
            offsetRadius: 2,
            showNavigation: false,
            config: { tension: 120, friction: 14 },
            goToSlideDelay: 200,
            products: products,
            slides: slides,
            blogPosts: []
        };

        this.onNextSlide = () => {
            this.setState({
                goToSlide: this.state.goToSlide + 1,
            });
        };
        this.onPreviousSlide = () => {
            this.setState({
                goToSlide: this.state.goToSlide - 1,
            });
        };

    }
    
    async componentDidMount() {
        try {
            const resp = await fetch("/api/Blog/ListPosts?take=3");
            const parsed = await resp.json();
            this.setState({ ...this.state, blogPosts: parsed });
        } catch (err) {
            console.error(`Can't load blog articles: ${err}`);
        }
    }    
    
    render() {
        const i = ((this.state.goToSlide % this.state.products.length) + this.state.products.length) %
            this.state.products.length;
        const descriptionDom = this.state.products[i].homePageDescription
            .split("\n").map(function (item, idx) {
                return (
                    <p key={idx}>
                        {item}
                    </p>
                );
            });
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Soundspear - High end digital sound processing - VST - VST3 - AU</title>
                    <meta name="description" content="Bundling innovation into premium VST plugins by combining cutting-edge algorithms and traditional hardware emulation" />
                </Helmet>
                <Container fluid>
                    {/* Black Friday Banner
                    <Row className="justify-content-center" style={{ backgroundColor: "black" }} >
                        <Col className="text-center" xs={{ size: 12 }} sm={{ size: 8 }} md={{ size: 6 }} lg={{ size: 4 }}>
                            <Link to="/catalog">
                                <img className="embed-responsive" src="/images/bundles/black-friday-banner.jpg" alt="Black Friday promo" width="600px" />
                            </Link>
                        </Col>
                    </Row>
                    */}
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <h1 className="menu-subtitle">High-end digital sound processing - VST - VST3 - AU</h1>
                        </Col>
                    </Row>
                    <Row className="home-carousel">
                        <Col sm={{ size: 12, order: 1 }} lg={{ size: 4, order: 1, offset: 1 }
                        } className="carousel-pictures-col">
                            <Carousel
                                slides={this.state.slides}
                                goToSlide={this.state.goToSlide}
                                goToSlideDelay={this.state.goToSlideDelay}
                                offsetRadius={this.state.offsetRadius}
                                showNavigation={this.state.showNavigation}
                                animationConfig={this.state.config} />
                        </Col>
                        <Col sm={{ size: 12, order: 2 }} lg={{ size: 4, order: 2, offset: 1 }} className="my-auto mr-md-5 home-product-description">
                            <div>
                                <h2>{this.state.products[i].name}</h2>
                                <h3>{this.state.products[i].shortDescription}</h3>
                                {descriptionDom}
                            </div>
                            <Link className="btn btn-primary btn-soundspear-primary" to={`/product/${this.state.products[i]
                                .permalink}`}>Listen</Link>
                            <hr />
                            <Row className="justify-content-between">
                                <Col className="col-auto">
                                    <img className="home-carousel-control" src="/images/left-arrow.svg" height="50px" onClick={
                                        () => this.onPreviousSlide()} alt="Previous carousel product" />
                                </Col>
                                <Col className="col-auto">
                                    <img className="home-carousel-control" src="/images/right-arrow.svg" height="50px" onClick={
                                        () => this.onNextSlide()} alt="Next carousel product" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="home-blog-section justify-content-center">
                        <RecommendedPosts posts={this.state.blogPosts} title="Our latest posts" />
                    </Row>
                    <Row className="home-formula-section justify-content-center p-5">
                        <Col xs={{ size: 12 }} className="text-center">
                            <h2>
                                <Link style={{color:"black"}} to="/product/formula#free">FORMULA IS OUT</Link>
                            </h2>
                        </Col>
                        <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }
                        } className="mt-4">
                            <p>
                                <b>Formula is a tool to create your own plugins inside your DAW and access hundreds
                                    of pre-made plugins from the community.</b>
                            </p>
                            <p>
                                🎸Musicians and sound engineers: Browse and use hundreds of available innovative effects.
                                <br/>
                                👨‍💻 Developers: Live code and test your effects right inside your DAW.
                            </p>
                            <Link className="btn btn-primary btn-soundspear-primary" to={"/product/formula#free"
                            }>Learn more</Link>

                        </Col>
                        <Col xs={{ size: 12, order: 1 }} md={{ size: 4, order: 2 }}>
                            <img className="embed-responsive formula-square-logo-img mt-2" src="/images/products/formula/formula-logo-square.jpg" alt="Soundspear Formula Logo" />
                        </Col>
                    </Row>
                    <Row className="home-capsule-section justify-content-center">
                        <Col xs={{ size: 12 }} className="text-center">
                            <h2 className="mt-5">
                                SOVNDSPEAR
                            </h2>
                            <h1>
                                <u>Capsule Collection</u>
                            </h1>
                        </Col>
                        <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }
                        } className="home-capsule-section-description">
                            <p>
                                <b>Soundspear presents the Capsule Collection. Carefully crafted audio plugins targeting specific enhancements.</b>
                            </p>
                            <p>
                                Every Capsule is a mixture of advanced audio processing techniques. A Capsule VST typically includes a spectrum splitter, which radiates frequency blends to non-linear processing units: custom compression; innovative distortion algorithms; spatialization and convolutive or digital color emulations.
                            </p>
                            <Link className="btn btn-primary btn-soundspear-primary" to={"/catalog?c=capsule"
                            }>Browse the collection</Link>

                        </Col>
                        <Col xs={{ size: 12, order: 1 }} md={{ size: 4, order: 2 }}>
                            <img className="embed-responsive capsule-collection-img" src="/images/capsule-collection.jpg" alt="Soundspear capsule collection" />
                        </Col>
                    </Row>
                    <Row className="home-m1-section justify-content-center">
                        <Col className="col-auto">
                            <img className="embed-responsive m1-img" src="/images/m1_compat.png" alt="Soundspear compatible with Apple M1" />
                        </Col>
                        <Col className="m1-heading col-auto" xs={{ offset: 1 }}>
                            <h3>All of our plugins are now</h3>
                            <h3>compatible with</h3>
                            <h3><b>Apple M1</b></h3>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}