import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";
import PropTypes from "prop-types";

import "../../style/form.scss";

export class BaseForm extends Component {
    static displayName = BaseForm.name;

    constructor(props) {
        super(props);
        let state = {
            sending: false,
            sent: false,
            error: "",
            recaptchaLoaded: false,
            captcha: "",
            formContent: {}
        };
        
        if (props.location.state && "origin" in props.location.state) {
            state.origin = props.location.state.origin;
        }
        else {
            state.origin = "";
        }
        
        this.state = state;
    }

    onFormUpdate(event) {
        const target = event.target;
        const name = target.name;

        const newFormContent = {
            ...this.state.formContent,
            [name]: target.value
        };

        this.setState({
            formContent: newFormContent
        });
    }

    addToForm(properties) {
        const newFormContent = {
            ...this.state.formContent,
            ...properties
        };
        console.log(newFormContent);

        this.setState({
            formContent: newFormContent
        });
    }

    parseModelStateErrors(json) {
        let errStr = "";
        const err = json["errors"];
        Object.keys(err).forEach(function(prop) {
            errStr = `${errStr}\n${err[prop]}`;
        });
        return errStr;
    }

    async submitForm(event) {
        event.preventDefault();
        this.setState({ sending: true, sent: false, error: "" });
        const response = await fetch(this.props.postApiEndpoint,
            {
                method: "POST",
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(this.state.formContent)
            });
        if (response.status === 200) {
            const data = await response.text();
            if (data) {
                this.setState({ error: data.substring(1, data.length - 1) });
            }
        } else {
            const data = await response.text();
            try {
                const errStr = this.parseModelStateErrors(JSON.parse(data));
                this.setState({ error: errStr });
            } catch (e) {
                if (data) {
                    this.setState({ error: data.substring(1, data.length - 1) });
                } else {
                    this.setState({ error: "An unknown error occurred" });
                }
            }
        }
        this.setState({ sending: false, sent: true });
        try {
            window.grecaptcha.reset();
        } catch(e) {}
    }

    recaptchaLoaded(e) {
        window.grecaptcha.ready(async () => {
            const token = await window.grecaptcha.execute(
                "6LcdUd8ZAAAAABJnbLMn41JhumgZ3CHSAMpmXGpO",
                { action: "submit" });

            const newFormContent = {
                ...this.state.formContent,
                captcha: token
            };


            this.setState({
                formContent: newFormContent
            });
        });
    }

    withRecaptcha() {
        if (!this.state.recaptchaLoaded) {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=6LcdUd8ZAAAAABJnbLMn41JhumgZ3CHSAMpmXGpO";
            script.addEventListener("load", this.recaptchaLoaded.bind(this));
            document.body.appendChild(script);
            this.setState({ recaptchaLoaded: true });
        }
    }

    renderLoadingScreen(message) {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center col-auto">
                        <h4>{message}</h4>
                        <BounceLoader
                            css={css`
	                            margin: auto;`}
                            size={100}
                            color={"#d4af37"}/>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidMount() {
        if (this.props.useRecaptcha) {
            this.withRecaptcha();
        }
    }

    renderForm() {
        return (
            <Container fluid>
                <div className="alert alert-danger text-center" role="alert" hidden={this.state.error ? false : true}>
                    {this.state.error}
                </div>

                <Row className="form-section justify-content-center">
                    <Col className="col-auto pt-5">
                        <form onSubmit={(e) => { this.submitForm(e); }}>
                            {React.Children.map(this.props.children,
                                child =>
                                React.cloneElement(child,
                                    {
                                        onFormUpdate: this.onFormUpdate.bind(this),
                                        addToForm: this.addToForm.bind(this),
                                        formSent: this.state.sent && !this.state.error,
                                        origin: this.state.origin
                                    })
                            )}
                        </form>
                    </Col>
                </Row>

                <small>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </small>
            </Container>
        );
    }

    render() {
        if (this.state.sending) {
            return this.renderLoadingScreen(this.props.sendingMessage);
        } else {
            return this.renderForm();
        }
    }
}

BaseForm.propTypes = {
    postApiEndpoint: PropTypes.string.isRequired,
    useReCaptcha: PropTypes.bool
};