import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import "../../style/services-partners.scss";

export class PurchaseComplete extends Component {
    static displayName = PurchaseComplete.name;

    componentDidMount() {
        document.title = `Thank You | Soundspear - High end digital sound processing - VST - AU`;
    }

    render() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <h1 className="menu-subtitle">High-end digital sound processing - VST - AU - RTAS</h1>
                    </Col>
                </Row>

                <Row className="justify-content-center mt-5">
                    <Col className="text-center">
                        <h2>
                            Thank you for your purchase
                        </h2>
                        <p>
                            A receipt has been sent to you by email.
                        </p>
                        <p>
                            <br />You can find your new product in the <Link to="/myaccount">My Account</Link> page.
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}