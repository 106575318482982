import React, { Component, Fragment } from "react";
import { Collapse, Container, Row, Col, Modal } from "reactstrap";
import { withRouter } from "react-router-dom";

import "../../style/shoppingcart.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class ShoppingCart extends Component {
    static displayName = ShoppingCart.name;

    constructor (props) {
        super(props);

        this.state = {
            productsInCart: [],
            isCartToggled: false,
            paddleLoaded: false,
            redirectToRegister: false,
            isOrderConfirmedModalOpen: false
        };

        props.cartManager.subscribe((items) => this.updateItemsCount(items));
    }
    
    async toggleCheckout() {
        if (!this.props.loginManager.isLoggedIn()) {
            this.props.history.push({
                pathname:"/register", 
                state: {origin:"checkout"}
            })
            return;
        }
        const payLink = await this.props.cartManager.getPayLink();
        if (!payLink) {
            return;
        }
        window.Paddle.Checkout.open({
            override: payLink
        });
    }

    componentDidMount() {
        this.setState({ productsInCart: this.props.cartManager.products });

        if (!this.state.paddleLoaded && !window.Paddle) {
            const script = document.createElement("script");
            script.src = "https://cdn.paddle.com/paddle/paddle.js";
            script.addEventListener("load", () => {
                window.Paddle.Setup({
                    vendor: 123545,
                    eventCallback: function (data) {
                        if (data.event === "Checkout.Complete") {
                            console.log("Checkout success");
                            this.setState({ isOrderConfirmedModalOpen: true, productsInCart: [] });
                        }
                    }
                });
            });
            document.body.appendChild(script);
            this.setState({ paddleLoaded: true });
        }
    }

    updateItemsCount(items) {
        this.setState({ productsInCart: items });
        this.setState({ isCartToggled: true });
        window.scrollTo(0, 0);
    }

    render() {
        if (this.state.productsInCart.length === 0) {
            return null;
        }

        return (
            <Fragment>
                <Modal isOpen={this.state.isOrderConfirmedModalOpen} tabindex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Thank you for your purchase</h5>
                                <button type="button" className="close" aria-label="Close"
                                        onClick={() => { this.setState({ isOrderConfirmedModalOpen: false }) }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>You can go to 'My Account' page in order to download your purchased products.
                                <br />
                                    Our payment gateway can take up to five minutes in order to process your order and link the products to your account.
                                    </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                    onClick={() => { this.setState({ isOrderConfirmedModalOpen: false})}}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Container fluid className="mb-3 mb-md-0" style={{ height: 0 }}>
                <Row className="justify-content-end">
                      <div className="card col-cart" style={{ zIndex: 999 }}>
                        <div className="text-center float-md-right p-1 pt-2 cart-header"
                             onClick={() => { 
                                 this.setState({ isCartToggled: !this.state.isCartToggled });
                             }}>
                            <span className="">
                                <FontAwesomeIcon icon={["fas", "shopping-cart"]} size="sm" className="mr-2"/>
                                {this.state.productsInCart.length} -
                            </span>
                            <span className="ml-2">
                                View Cart
                            </span>
                        </div>
                        { /* The 'show' class is set dynamically to remove the buggy animation */
                        }
                        <Collapse className="cart-content" isOpen={this.state.isCartToggled}>
                            {this.state.productsInCart.map((product, idx) => {
                                return (
                                    <div key={idx} className="cart-listing">
                                        <Row>
                                            <Col className="col-auto">{product.priceEuro} &euro;</Col>
                                            <Col className="col-auto">{product.name}</Col>
                                            <Col className="text-right mr-2">
                                                <FontAwesomeIcon icon={["fas", "times"]
} size="sm" className="remove-product"
                                                onClick={ () => {
                                                    this.props.cartManager.removeProduct(product);
                                                }}/>
                                            </Col>
                                        </Row>
                                    </div>);
                            })}
                            <hr className="cart-content-separator"/>
                            <div className="cart-listing font-weight-bold">
                                <Row>
                                    <Col className="col-auto">{this.props.cartManager.getTotalPrice()} &euro;</Col>
                                    <Col className="col text-center">TOTAL</Col>
                                </Row>
                            </div>
                            <hr className="cart-content-separator"/>
                            <Row className="justify-content-center">
                                <Col className="col-auto">
                                    <button className="btn btn-soundspear-primary text-center btn-checkout"
                                    onClick={() => this.toggleCheckout()}>
                                        <FontAwesomeIcon icon={["fas", "cart-plus"]} size="sm" className="mr-2"/>
                                        Checkout
                                    </button>
                                </Col>

                            </Row>
                        </Collapse>
                    </div>
                </Row>
                </Container>
            </Fragment>
        );
    }
}

export default withRouter(ShoppingCart);