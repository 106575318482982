import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import "../../style/services-partners.scss";

export class NoCommercialMailsConfirmation extends Component {
    static displayName = NoCommercialMailsConfirmation.name;

    componentDidMount() {
        document.title = `Unsubscribed | Soundspear - High end digital sound processing - VST - AU`;
    }

    render() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <h1 className="menu-subtitle">High-end digital sound processing - VST - AU - RTAS</h1>
                    </Col>
                </Row>

                <Row className="justify-content-center mt-5">
                    <Col className="text-center">
                        <h2>
                            Success
                        </h2>
                        <p>
                            You have been successfully unregistered from the Soundspear Newsletter mailing list.
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}