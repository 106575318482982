import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "../../style/footer.scss";

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer className="footer-global">
                <Container fluid className="text-center text-md-left">
                    <Row>
                        <Col md={{ size: 2, offset: 2 }}>
                            <h5>Shop</h5>
                            <ul>
                                <li className="list-unstyled">
                                    <Link to="/catalog?c=effect">Effects</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/catalog?c=capsule">Capsules</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/catalog?c=bundles">Bundles</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/services#on-demand">Branded VSTs</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={{ size: 2 }}>
                            <h5>Corporate</h5>
                            <ul>
                                <li className="list-unstyled">
                                    <Link to="/legal">Legal Notice</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/privacy">Privacy &amp; GDPR</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/partners#resellers">Resellers</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/services#consulting">Consulting</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={{ size: 2 }}>
                            <h5>Customers</h5>
                            <ul>
                                <li className="list-unstyled">
                                    <Link to="/register">Register</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/login">Login</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/contact">Support</Link>
                                </li>
                                <li className="list-unstyled">
                                    <Link to="/partners#content-creators">Be an ambassador</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={{ size: 2 }}>
                            <ul>
                                <div className="list-unstyled row text-center">
                                  <li className="mt-2 col-4 col-md-12">
                                    <a href="https://www.instagram.com/soundspear.plugins/" target="_blank">
                                      <FontAwesomeIcon icon={["fab", "instagram"]} size="2x"/>
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCFXakerIFwiHC7ACjb-gbdA" className="ml-2" target="_blank">
                                      <FontAwesomeIcon icon={["fab", "youtube"]} size="2x" />
                                    </a>
                                  </li>
                                  <li className="mt-2 col-4 col-md-6">
                                    <a href="https://www.adsrsounds.com/software-developer/soundspear/" target="_blank">
                                      <img src="/images/adsr-logo.svg" width="75px" style={{ opacity: 0.4 }} />
                                    </a>
                                  </li>
                                  <li className="mt-2 col-4 col-md-6">
                                    <a href="https://audioplugin.deals/developer/soundspear/" target="_blank">
                                      <img src="/images/adp-logo.png" width="75px" style={{ opacity: 0.5 }} />
                                    </a>
                                  </li>
                                  <li className="mt-2 ml-2 col-4 col-md-6">
                                    <a href="https://www.kvraudio.com/developer/soundspear" target="_blank">
                                      <img src="/images/kvr-logo.png" width="60px" style={{ opacity: 0.5 }} />
                                    </a>
                                  </li>
                                </div>
                                
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <div className="footer-copyright text-center py-3">
                    <Container fluid>
                        <p>
                            &copy; {new Date().getFullYear()} Copyright: <Link to="/"> soundspear.com </Link>
                            <br/>
                            <img src="/images/soundspear-logo-footer.png" alt="The company logo"/>
                        </p>
                    </Container>
                </div>
            </footer>
        );
    }
}