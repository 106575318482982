import React, { useEffect } from "react";

import { Container } from "reactstrap";

export function Legal(props) {
    useEffect(() => {
        document.title = `General terms and conditions | Soundspear - High end digital sound processing - VST - AU`;
    }, []);
    return (<Container className="mt-5">
                <h1>General terms and conditions</h1>
                <p>The general conditions of sale define the conditions of access and use of the site accessible via <a href="https://soundspear.com">soundspear.com</a>, <a href="https://www.soundspear.com">www.soundspear.com</a> (below, the Website) and any of the products and services disclosed by the Website. The Website is operated by the self-organization -here below the Company- registered in France as RCS PARIS 883 009 102, under the name of its owner and sole operator Antoine Champion. The use of the site implies the unconditional acceptance of all conditions specified in this document. The conditions applicable to the order of a product by a customer are those in force on the day of the The user must regularly consult and read carefully the paragraphs below as well as the legal notices, as they may be subject to change and updating due to legal, judicial or commercial criteria.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>1. OBJECT</strong>
                </p>
                <p>The&nbsp;<strong>General Terms and Conditions</strong>&nbsp;govern the conditions of ordering, payment, delivery and management of any returns of products ordered by customers on the&nbsp;Website.</p>
                <p>The GTC are opposable to the customer who declares having read and accepted them by ticking the box "I accept the terms and conditions of sale" before the implementation of the online ordering procedure.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>2. CUSTOMER</strong>
                </p>
                <p>The client of the site is the major physical person and legally capable having the quality of consumer.</p>
                <p>During his first order on the site, the customer will be required to open a customer account and submit specific information, including but not limited to a username, password, email address, and billing information, as detailed in our <a href="https://soundspear.com/privacy">Privacy Policy</a>. The information provided must be complete, accurate and up-to-date.</p>
                <p>The Company reserves the right not to respond to an order if it does not come from a customer meeting the criteria set above.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>3. PRODUCT</strong>
                </p>
                <p>The characteristics of the products are presented on the Website inside each product sheet.</p>
                <p>If the customer requires more information, he can contact customer service using the <a href="https://soundspear.com/contact">Contact page</a>.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>4. ORDER</strong>
                </p>
                <p>To place an order, the customer must follow these steps:</p>
                <p>- add one or more products in his basket;</p>
                <p>- ensure that the contents of the basket correspond to the desired purchase;</p>
                <p>- finalize your order and fill out the form to indicate the necessary personal data;</p>
                <p>- choose the method of payment;</p>
                <p>- take note and accept the GSC;</p>
                <p>- proceed to payment.</p>
                <p>It is up to the customer to check the details of the order - including the total price of his order taking into account the price of the products, terms and delivery charges, VAT - and the accuracy of the information he communicates.</p>
                <p>The prices of the products are indicated on the website in euros, VAT included.</p>
                <p>The Company reserves the right to refuse, cancel and terminate orders at any time for legitimate and legal reasons. In these cases, the customer will be informed by phone or email.</p>
                <p>In the event that the products presented on the site are no longer available or for sale at the time of sending your order, the Company must notify you promptly, before 30 working days from the day following your order, the possible unavailability ordered products. In case of payment of the price, you will be refunded the full amount advanced.</p>
                <p>The contract is deemed concluded upon receipt of the order after verification of the accuracy of the data relating to the order.</p>
                <p>Following the validation of the order, an acknowledgment of receipt will be sent by email to the customer, containing a summary of the order.</p>
                <p>The prices of the products can be modified at any time and without notice in particular because of evolution of the economic, legislative and fiscal framework. The products are billed on the basis of the tariff in force when the customer places an order.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>5. PAYMENT</strong>
                </p>
                <p>
                    The site offers a payment via
                    <strong>
                        <a href="http://paddle.com/">Paddle.com</a>
                    </strong>. When the order is finalized, we will confirm the payment by sending the customer a summary email.&nbsp;
                    <strong>
                        <a href="http://paddle.com/">Paddle.com</a>
                    </strong>&nbsp;is the Merchant of Record for all our orders and provides all customer service inquiries and handles returns.
                </p>
                <p>The prices displayed on the site are expressed in euros, all taxes included (French VAT and other taxes applicable on the day of the order).</p>
                <p>&nbsp;</p>
                <p>
                    <strong>6. DELIVERY</strong>
                </p>
                <p>All orders placed on the Website are delivered digitally via the internet. They are not sent via post or delivered to the customer as tangible goods.</p>
                <p>In order to use a purchased product, the customer must follow these steps:</p>
                <p>(1) download the purchased product on <strong>My Account</strong> page</p>
                <p>(2) install the product to a compatible computer</p>
                <p>(3) run the product as a Virtual Studio Technology (VST) inside a digital audio workstation</p>
                <p>(4) when prompted to activate the Program, copy the displayed Computer ID</p>
                <p>(5) generate a license key on My Account page by entering the previous Computer ID</p>
                <p>(6) enter the license key into the Program</p>
                <p>If the User has bought the program through an authorized reseller, the User has to go to My Account page, click Register a new product, enter the product key given by the reseller, and follow the steps (1) to (6) as described above.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>7. USER LICENSE AGREEMENT</strong>
                </p>
                <p>All Company products are bound by the terms of the User License Agreement. A copy of this agreement is also supplied within the zip file of all products purchased.</p>
                <p class="page-desc">A. GRANT OF LICENSE.&nbsp;The programs are licensed to you, not sold to you. All rights not expressly granted to the user are reserved. Only the original purchaser of the program has a legal right to use it, within their own "original" music compositions and/or in advertisements, soundtracks, and television or film productions.&nbsp;The content can be used for both commercial and non-commercial use. However, it may not be used in the creation of any competitive product, such as a Sample Pack, Sample CD, Sample DVD, Virtual Instrument, Expansion Pack, or any other collection of sound or loop elements in any format.</p>
                <p class="page-desc">B. TRIAL VERSION. If you have downloaded a trial version of the Product, then the Product can not be&nbsp; used commercially. The demonstration product will have the same functionnalities than the full product, with the following restriction:</p>
                <p class="page-desc">- The program will output a white noise of five (5) seconds every minute.</p>
                <p class="page-desc">- The program will stop after one hour, and the user will have to restart it.</p>
                <p class="page-desc">C. COPYRIGHT. The Company is the sole owner of the products and all trade secrets, copyrights, patents and other intellectual property rights therein (other than, if relevant, open source, or intellectual property rights licensed from third parties). Therefore, you must treat the product like any other copyrighted material (e.g., a book or musical recording). All other rights not expressly provided herein are hereby reserved by the Company.</p>
                <p class="page-desc">4. OTHER RESTRICTIONS. You may not and you may not permit others to:<br/>a) Reverse engineer, decompile, disassemble or otherwise derive source code from the program;<br/>b) Modify or prepare derivative works of the program;<br/>c) Copy the program, other than as specifically set forth herein; and/or<br/>d) use the program in any manner that infringes the intellectual property or other rights of another party.&nbsp;</p>
                <p class="page-desc">5. UPDATES. If the program is an update to a previous version (the "UPDATE"), you must possess a valid License for the previous version, in order to use the Update. All Updates are provided to you on a "license exchange" basis and you agree that by using an Update, you no longer have a License to use any previous version of the program.</p>
                <p>6. LEGAL ACTIONS. All copying, lending, duplicating, re-selling, renting, uploading, file sharing or unauthorized trading of this content (or any part of this content) is prohibited by international copyright law. The Company reserves the right to use anti-tamper and piracy protections and will not hesitate to take legal action against any persons, company or individual who fail to adhere to the terms of this agreement.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>7. REFUND POLICY</strong>
                </p>
                <div>
                    <p>All sales on the Website are final. The issue of a refund is always made at the discretion of the Company. All decisions made by the Company are final.</p>
                    <p>The Company will refund your full purchase price on orders where:</p>
                    <ul>
                        <li>Your bank / Paypal account was illegally charged (fraud)</li>
                        <li>The failure to deliver the product is caused solely by the Company.</li>
                    </ul>
                    <p>The Company reserves the right to refuse refund if the issued serial number has been issued, made available to the customer through the user account and used to authorize the software.</p>
                    The Company does not refund products, which:
                </div>
                <ul>
                    <li>Cannot be used by the customer due to hardware limitations</li>
                    <li>Cannot be used by the customer due to software limitations</li>
                    <li>Cannot be used by the customer due to insufficient knowledge / know-how</li>
                </ul>
                <p>
                    <strong>We strongly advises checking what software and/or hardware is required to use the products and what are the minimum hardware requirements to use the software. Such information is always provided on the product pages.&nbsp;The customer bears the responsibility to have knowledge required to use the product.</strong>
                </p>
                <p>Contact our support staff using the <a href="https://soundspear.com/contact">Contact page</a> if you feel you qualify for a refund.</p>
                <p>&nbsp;</p>
                <p>
                    <strong>8. LIMITATION OF LIABILITY</strong>
                </p>
                <p>Under no circumstances shall the Company be liable for any direct, indirect, incidental, consequential loss, liability or damages arising under these terms and conditions or license agreement or in connection with the Website. The foregoing limitation of liability shall apply in any action, whether in contract, tort or any other claim. The user hereby acknowledges that this paragraph shall apply to all content, merchandise and services available through the Website.</p>

            </Container>
    );
}