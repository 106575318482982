export class ReleasesManager {
    static async getDownloadLink(endpoint) {
        let downloadLink = "";
        const response = await fetch(endpoint,
            {
                method: "GET"
            });
        if (response.status === 200) {
            const data = await response.text();
            if (data) {
                downloadLink = data;
            }
        }

        return downloadLink;
    }

    /**
     * 
     * @param {any} htmlDocument dom
     * @param {any} productUuid
     * @param {any} releaseType any of "full", "private", "trial", "manual"
     * @param {any} releasePlatform any of "windows", "mac"
     */
    static async downloadRelease(htmlDocument, productUuid, releaseType, releasePlatform) {
        const endpoint = `api/licensing/GetDownloadLink?productUuid=${productUuid}`
            + `&releaseType=${releaseType}&releasePlatform=${releasePlatform}&localDownload=true`;

        const downloadLink = await ReleasesManager.getDownloadLink(endpoint);

        const link = htmlDocument.createElement("a");
        link.href = downloadLink;
        htmlDocument.body.appendChild(link);
        link.click();
        htmlDocument.body.removeChild(link);
    }
}