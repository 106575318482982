import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import ReactPlayer from "react-player"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Helmet } from "react-helmet";

import { ReleasesManager } from "../layout/ReleasesManager.js"

import "../../style/productpage.scss";
import {RecommendedPosts} from "../layout/RecommendedPosts";

export class ProductPage extends Component {
    static displayName = ProductPage.name;

    constructor(props) {
        super(props);

        const product = props.products.find(p => p.permalink === this.props.match.params.productPermalink);

        this.state = {
            product: product,
            downloadTrialWindowsDisabled: false,
            downloadTrialMacDisabled: false,
            downloadManualDisabled: false
        };

        this.renderProductCard = this.renderProductCard.bind(this);
    }

    addToCart() {
        this.props.cartManager.addNewProduct(this.state.product);
        setTimeout(() => {
            this.setState({
              pageContents: this.getPageContents(this.state.product)
            });
          },
            500);
        window.scrollTo(0, 0);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10796810978/u1S7CIiS1_8CEOKFqZwo' });
    }

    renderProductCard(product) {
        let addToCartDom;
        
        if (product.isEndOfLife) {
            addToCartDom = (<button className="btn btn-soundspear-primary mb-2" disabled>
                <FontAwesomeIcon icon={["fas", "cart-plus"]} size="sm" className="mr-2"/>
                Not for sale
            </button>);
        } 
        else if (this.props.cartManager.contains(product)) {
            addToCartDom = (
                <button className="btn btn-soundspear-primary mb-2" disabled>
                    <FontAwesomeIcon icon={["fas", "cart-plus"]} size="sm" className="mr-2"/>
                    Already in cart
                </button>);
        } else {
            addToCartDom = (
                <button className="btn btn-soundspear-primary mb-2"
                        onClick={() => { this.addToCart() }}>
                    <FontAwesomeIcon icon={["fas", "cart-plus"]} size="sm" className="mr-2"/>
                    Add to cart
                </button>);
        }
        let userManualDom = <Fragment />;
        if (!product.isBundle) {
            userManualDom = (
              <button className="btn btn-soundspear-secondary mb-2 ml-2"
                    disabled={this.state.downloadManualDisabled}
                    onClick={async () => {
                      this.setState({ downloadManualDisabled: true });
                      await ReleasesManager.downloadRelease(document, this.state.product.uuid, "manual", "");
                      this.setState({ downloadManualDisabled: false });
                    }}>
                    <FontAwesomeIcon icon={["fas", "file-pdf"]} size="sm" className="mr-2" />
                    User manual
                </button>
              );
        }
        const availableWindows = product.compatibility.toUpperCase().indexOf("WINDOWS") !== -1;
        const availableMac = product.compatibility.toUpperCase().indexOf("MAC") !== -1;

        let windowsTrialDom = <Fragment />;
        let macTrialDom = <Fragment />;

        if (!product.isBundle && availableWindows) {
            windowsTrialDom = (
                <button className="btn btn-soundspear-secondary"
                    disabled={this.state.downloadWindowsDisabled}
                    onClick={async () => {
                        this.setState({ downloadWindowsDisabled: true });
                        await ReleasesManager.downloadRelease(document, product.uuid, "trial", "windows");
                        this.setState({ downloadWindowsDisabled: false });
                    }}>
                    <FontAwesomeIcon icon={["fab", "windows"]} size="sm" className="mr-2" />
                        Trial (Windows)
                </button>
            );
        }
        if (!product.isBundle && availableMac) {
            macTrialDom = (
                <button className="btn btn-soundspear-secondary ml-2"
                    disabled={this.state.downloadMacDisabled}
                    onClick={async () => {
                        this.setState({ downloadMacDisabled: true });
                        await ReleasesManager.downloadRelease(document, product.uuid, "trial", "mac");
                        this.setState({ downloadMacDisabled: false });
                    }}>
                    <FontAwesomeIcon icon={["fab", "apple"]} size="sm" className="mr-2" />
                        Trial (Mac)
                </button>
            );
        }

        let priceDom;
        if (product.retailPriceEuro !== product.priceEuro) {
            priceDom =
                <Fragment>
                    <h4 className="mt-3 retail-price"><strike>&euro; {product.retailPriceEuro.toFixed(2)}</strike></h4>
                    <h2>&euro; {product.priceEuro.toFixed(2)}</h2>
                </Fragment>;
        }
        else {
            priceDom =
                <Fragment>
                    <h2>&euro; {product.priceEuro.toFixed(2)}</h2>
                </Fragment>;
        }

        return (
            <div>
                <h1 className="product-page-name">{product.name}</h1>
                <h3 className="product-page-short-desc">{product.shortDescription}</h3>
                {priceDom}
                <div className="mt-3 product-page-compatibility">{product.compatibility}</div>
                <div className="mt-3">
                    <div>
                        {addToCartDom}
                        {userManualDom}
                    </div>
                    <div>
                        {windowsTrialDom}
                        {macTrialDom}
                    </div>
                </div>
            </div>);
    }

    getPageContents(product) {
        const pageContents = product.productPage.map((e, i) => {
            let contentElement;
            switch (e.type) {
            case "youtube":
                contentElement = this.generateYoutubeContent(e);
                break;
            case "image":
                contentElement = this.generateImageContent(e);
                break;
            case "text":
                contentElement = this.generateTextContent(e);
                break;
            default:
                contentElement = undefined;
                break;
            }
            return contentElement;
        });

        pageContents.splice(1, 0, this.renderProductCard(product));

        return pageContents;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.productPermalink !== this.props.match.params.productPermalink) {
            const product = this.props.products.find(p => p.permalink === this.props.match.params.productPermalink);
            this.setState({
                product: product,
                pageContents: this.getPageContents(product)
            });
        }
        document.title = `${this.state.product.name} | Soundspear - High end digital sound processing - VST - AU`;
    }

    generateYoutubeContent(e) {
        const border = e.border ? " border-soundspear-primary" : "";

        if (e.width === "full") {
          return (
              <ReactPlayer
                    className={`react-player product-content product-youtube-content${border} text-align ml-auto mr-auto`}
                    url={e.content}
                    controls={true} pip />);
        } else {
          return (
            <ReactPlayer
                    className={`react-player product-content product-youtube-content${border}`}
                    url={e.content}
                    width="100%"
                    height="100%"
                    controls={true} pip />);
        }
    }

    generateImageContent(e) {
        const border = e.border ? " border-soundspear-primary" : "";
        return (
            <div className="product-content product-image-content text-center">
                <img alt={"Interface of the plugin"}
                     src={e.content}
                     className={`img-fluid my-auto${border}`}/>
            </div>);

    }

    generateTextContent(e) {
        const border = e.border ? " border-soundspear-primary" : "";
        return (
            <div className={`product-content product-text-content my-auto text-justify${border}`}>
                <p dangerouslySetInnerHTML={{ __html: e.content }}/>
            </div>);
    }

    render() {
        if (!this.state.product || this.state.product.customComponent) {
            return (<Fragment />);
        }
        const pageContents = this.getPageContents(this.state.product);
        return (
            <Fragment>                
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`${this.state.product.name} | Soundspear - High end digital sound processing - VST - AU`}</title>
                    <meta name="description" content={this.state.product.homePageDescription} />
                </Helmet>
                <Container className="product-page-container" fluid>

                    <Row className="">
                        {pageContents.map((columnContent, i) => {
                            const featureIdx = i < 1 ? i : i - 1; // Cart section has been added at idx 1
                            const colSize = this.state.product.productPage[featureIdx].width === "full" ? 12 : 6;
                            const colorClass = i % 4 < 2 ? "product-even-row" : "product-odd-row";
                            return (
                                <Col sm={{ size: 12 }} lg={{ size: colSize }} key={i}
                                    className={colorClass + " p-4 p-sm-5"}>
                                    {columnContent}
                                </Col>);
                        })}
                    </Row>
                    <Row>
                        <RecommendedPosts posts={this.state.product.recommendedPosts} />
                    </Row>
                </Container>
            </Fragment>
        );
    }
}