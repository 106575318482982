export class CartManager {
    constructor(loginManager) {
        this.products = [];
        this.hooks = [];

        this.restoreProducts();
    }

    subscribe(func) {
        this.hooks.push(func);
    }

    contains(product) {
        return this.products.findIndex(o => o.uuid === product.uuid) >= 0;
    }

    addNewProduct(product) {
        if (this.contains(product)) {
            return;
        }
        this.products.push(product);
        this.callHooks();
    }

    removeProduct(product) {
        this.products.splice(this.products.findIndex(o => o.uuid === product.uuid), 1);
        this.callHooks();
    }

    restoreProducts() {
        try {
            const previousCartContent = JSON.parse(sessionStorage.getItem("cart")) || [];
            this.products = previousCartContent;
        } catch (e) {
            sessionStorage.setItem("cart", "[]");
        }
    }

    saveProducts() {
        try {
            const cartContent = JSON.stringify(this.products);
            sessionStorage.setItem("cart", cartContent);
        } catch (e) {
            console.log(JSON.stringify(this.products));
        }
    }

    getTotalPrice() {
        return this.products.reduce((acc, p) => {
                return acc + p.priceEuro;
            },
            0);
    }

    callHooks() {
        this.hooks.forEach((f) => f(this.products));
        this.saveProducts();
    }

    async getPayLink() {
        let payLink = "";
        const productsUuids = this.products.map((p) => { return p.uuid });
        const response = await fetch("/api/checkout/generatepaylink",
            {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({ productsUuids: productsUuids })
            });
        if (response.status === 200) {
            console.log(response);
            payLink = await response.text();
            console.log(payLink);
        } else {
            console.log(`Error while trying to get a pay link (Unknown, status ${response.status})`);
        }

        return payLink;
    }
}