import React, {Component, Fragment} from "react";
import {Col, NavLink, Row} from "reactstrap";
import {HashLink as Link} from "react-router-hash-link";
import "../../style/recommended-post.scss";

export class RecommendedPosts extends Component {
    static displayName = RecommendedPosts.name;
    
    constructor(props) {
        super(props);
    }
    
    get componentTitle() {
        return this.props.title ? this.props.title : "Related content";
    }
    
    renderBlogCard(post) {
        return (
            <Col md={{ size: 4 }} sm={{ size: 12}}>
                <div className="recommended-thumb-container">
                    <NavLink className="recommended-post-link"
                             to={`/blog/${post.url}`} tag={Link} >
                        <img className="recommended-post-thumb"
                             alt={post.title + " thumbnail"}
                             src={post.thumbnailUrl}
                             height={150} />
                    </NavLink>
                </div>                
                <NavLink className="recommended-post-link"
                         to={`/blog/${post.url}`} tag={Link} >
                    <h4 className="recommended-post-title mt-2">{post.title}</h4>
                    <p className="recommended-post-description">{post.description}</p>
                </NavLink>
            </Col>
        );
    }
    
    render() {
        return (
            <Fragment>
                <div className="see-also">
                    <h2 className="text-center">{this.componentTitle}</h2>
                </div>
                <Row>
                    {this.props.posts.map(p => this.renderBlogCard(p))}
                </Row>
            </Fragment>
        );
    }
}