import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";

import "../../style/form.scss";

export class Logout extends Component {
    static displayName = Logout.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            success: false
        };
    }

    async logout() {
        await fetch("api/identity/logout",
            {
                method: "POST"
            });
        this.setState({ loading: false, success: true });
        this.props.loginManager.fetchUser();
    }

    renderLoadingScreen() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center col-auto">
                        <h4>Log out...</h4>
                        <BounceLoader
                            css={css`
	                            margin: auto;`}
                            size={100}
                            color={"#d4af37"}/>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidMount() {
        this.logout();
    }

    renderConfirmation() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <h1 className="menu-subtitle">High-end digital sound processing - VST - AU - RTAS</h1>
                    </Col>
                </Row>

                <div className={`alert text-center alert-${this.state.success ? "success" : "danger"}`}
                     role="alert">
                    {this.state.success
                        ? "Your are now logged out."
                        : "Unable to log out."}
                </div>
            </Container>
        );
    }

    render() {
        if (this.state.loading) {
            return this.renderLoadingScreen();
        } else {
            return this.renderConfirmation();
        }
    }
}