import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function ContactForm(props) {
    useEffect(() => {
        document.title = `Contact Form | Soundspear - High end digital sound processing - VST - AU`;
    }, []);
    return (
        <div>
            <div className="alert alert-warning" role="alert" hidden={props.formSent ? false : true}>
                Thank you for your message. We will address it as soon as possible.
            </div>

            <div className="card border-soundspear-black rounded-0">
                <div className="card-header p-0">
                    <div className="bg-black text-soundspear-primary text-center py-2">
                        <h3><FontAwesomeIcon icon={["fas", "envelope"]} size="sm" className="mt-3"/> Contact</h3>
                        <p className="m-0"></p>
                    </div>
                </div>
                <div className="card-body p-3">

                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={["fas", "user"]
} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="name" type="text" className="form-control" placeholder="Your name..." required
                                   onChange={(t) => { props.onFormUpdate(t) }}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={["fas", "envelope"]
} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="contact" type="text" className="form-control" placeholder="Your email/phone..." required
                                   onChange={(t) => { props.onFormUpdate(t) }}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={["fas", "comment"]
} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <textarea name="msg" className="form-control" cols="60" rows="6" required placeholder="Your message..."
                                onChange={(t) => { props.onFormUpdate(t) }} />
                        </div>
                    </div>

                    <div className="text-center">
                        <input type="submit" value="Send" className="btn btn-soundspear-primary-black btn-block py-2"/>
                    </div>
                </div>
            </div>

        </div>
    );
}