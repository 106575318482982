import React, {Component} from "react";
import {Container, NavLink} from "reactstrap";
import {Helmet} from "react-helmet";

import "../../style/blog.scss";
import BounceLoader from "react-spinners/BounceLoader";
import {HashLink as Link} from "react-router-hash-link";

export class BlogArticlesList extends Component {
    static displayName = BlogArticlesList.name;

    constructor(props) {
        super(props);
        this.state = { articles: null };
    }
    async componentDidMount() {
        try {
            const resp = await fetch("/api/Blog/ListPosts?take=50");
            const parsed = await resp.json();
            this.setState({ articles: parsed });
        } catch (err) {
            console.error(`Can't load blog articles: ${err}`);
            this.setState({ articles: null });
        }
    }
    
    async componentWillUnmount() {
        this.setState({ articles: null });
    }
    
    renderArticleListElement(a) {
        return <NavLink tag={Link} to={`/blog/${a.url}`}>
            <div className="article-list-item">
                <img className="img-thumbnail" alt="Thumbnail" src={a.thumbnailUrl}/>
                <span className="title">{a.title}</span>
                <span className="desc">{a.description}</span>
                <hr/>
            </div>
        </NavLink>;
    }
    
    renderArticleList() {
        if (!this.state.articles) {
            return <BounceLoader
                size={75}
                color={"#d4af37"}/>;
        }

        return (
            <div>
                {this.state.articles.map(a => this.renderArticleListElement(a))}
            </div>
        );
    }
    
    render() {
        return (
            <div className="formula-body">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Blog - Soundspear - VST - VST3 - AU</title>
                    <meta name="description" content="Bundling innovation into premium VST plugins by combining cutting-edge algorithms and traditional hardware emulation" />
                </Helmet>

                <Container className="product-page-container">
                    <h1 className="blog-home-title">Soundspear Blog & Articles</h1>
                    <h2 className="blog-home-subtitle">Audio plugins and Music Production</h2>
                    {this.renderArticleList()}
                </Container>
            </div>
        );
    }
}