import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../../style/services-partners.scss";

export class Services extends Component {
    static displayName = Services.name;

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Services | Soundspear - High end digital sound processing - VST - AU</title>
                    <meta name="description" content="Our expertise can be put at your disposal for audio engineering projects." />
                </Helmet>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <h1 className="menu-subtitle">High-end digital sound processing - VST - AU - RTAS</h1>
                        </Col>
                    </Row>

                    <Row className="first-section justify-content-center" id="consulting">

                        <Col md={{ size: 12 }} lg={{ size: 5 }} className="first-section-description">
                            <h2 className="services-partners-title">
                                Consulting
                            </h2>
                            <p>
                                <b>Many engineers at Soundspear come from the consulting world.</b>
                            </p>
                            <p>
                                Our expertise can be put at your disposal. We thrive in:
                                <ul>
                                    <li>Digital signal processing</li>
                                    <li>Applied physics and electrical/electromagnetism engineering</li>
                                    <li>Software engineering for audio applications</li>
                                    <li>Development in C++, C# and Python</li>
                                    <li>QA, Testing, Project management</li>
                                </ul>
                            </p>
                            <p>
                                Together we will study the goals of your projects, which can be carried out by contract work or delegation of skills.
                            </p>
                            <Link className="btn btn-primary btn-soundspear-primary" to="/contact">Contact Us</Link>

                        </Col>
                        <Col md={{ size: 12 }} lg={{ size: 5 }} className="hidden-sm my-auto">
                            <img className="embed-responsive first-img border-soundspear-primary" src="/images/services-consulting.jpg" alt="A programmer writing code"/>
                        </Col>
                    </Row>

                    <Row className="second-section justify-content-center" id="on-demand">

                        <Col xs={{ size: 12, order: 2 }} lg={{ size: 5, order: 1 }} className="hidden-sm my-auto">
                            <img className="embed-responsive second-img border-soundspear-primary" src="/images/services-on-demand.jpg" alt="A mastering deck"/>
                        </Col>

                        <Col xs={{ size: 12, order: 1 }} lg={{ size: 5, order: 2 }} className="second-section-description">
                            <h2 className="services-partners-title">
                                On-demand & Branded VSTs
                            </h2>
                            <p>
                                <b>Would you like us to design and create your own plugin?</b>
                            </p>
                            <p>
                                Having a tailored VST is a guarantee of having your needs fulfilled.
                            </p>
                            <p>
                                Through a back and forth between you and Soundspear, we will help you to translate your desires into a custom made plugin.
                            </p>
                            <p>
                                <b>Make your wish come true.</b>
                            </p>
                            <Link className="btn btn-primary btn-soundspear-primary" to="/contact">Contact Us</Link>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}