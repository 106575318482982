import React, { Component } from "react";
import { Route } from "react-router";

import { Layout } from "./components/layout/Layout";
import { Home } from "./components/pages/Home";
import { ProductPage } from "./components/pages/ProductPage";
import { Catalog } from "./components/pages/Catalog";
import { Partners } from "./components/pages/Partners";
import { Services } from "./components/pages/Services";
import { MyAccount } from "./components/pages/MyAccount";
import { Legal } from "./components/pages/Legal";
import { Privacy } from "./components/pages/Privacy";
import { Formula } from "./components/pages/Formula";

import { BaseForm } from "./components/layout/BaseForm";
import { ContactForm } from "./components/auth/ContactForm";
import { RegistrationForm } from "./components/auth/RegistrationForm";
import { ConfirmEmail } from "./components/auth/ConfirmEmail";
import { LoginForm } from "./components/auth/LoginForm";
import { ForgotPasswordForm } from "./components/auth/ForgotPasswordForm";
import { ResetPasswordForm } from "./components/auth/ResetPasswordForm";
import { Logout } from "./components/auth/Logout";
import { PurchaseComplete } from "./components/pages/PurchaseComplete";
import { NoCommercialMailsConfirmation } from "./components/pages/NoCommercialMailsConfirmation";

import { LoginManager } from "./components/auth/LoginManager";
import { CartManager } from "./components/layout/CartManager";

import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

import "./style/fonts.css"
import "./style/app.scss"
import {BlogArticlesList} from "./components/pages/BlogArticlesList";
import {BlogPost} from "./components/pages/BlogPost";

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        const loginManager = new LoginManager();
        const cartManager = new CartManager(loginManager);
        this.state = {
            products: null,
            loginManager: loginManager,
            cartManager: cartManager,
            productsLoading: true,
            userManagerLoading: true
        };
    }

    componentDidMount() {
        this.populateProductsData();
        this.populateUserData();
    }

    async populateProductsData() {
        const response = await fetch("/api/products/all");
        const data = await response.json();
        this.setState({ products: data, productsLoading: false });
    }

    async populateUserData() {
        await this.state.loginManager.fetchUser();
        this.setState({ userManagerLoading: false });
    }

    loadingOverlay() {
        const override = css`
        position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;  	
	margin: auto;
      `;
        return (
            <div className="application-loading-overlay">
                <BounceLoader
                    css={override}
                    size={100}
                    color={"#d4af37"}/>
            </div>
        );
    }

    render() {
        if (this.state.productsLoading || this.state.userManagerLoading) {
            return this.loadingOverlay();
        } else {
            return (
                <Layout products={this.state.products} loginManager={this.state.loginManager} cartManager={this.state
                    .cartManager}>
                    <Route exact path="/"
                           component={(props) => (<Home {...props} products={this.state.products} />)}/>
                    <Route exact path="/product/formula"
                           component={(props) => (<Formula {...props} products={this.state.products} cartManager={this
                               .state.cartManager}/>)}/>
                    <Route exact path="/product/:productPermalink"
                           component={(props) => (<ProductPage {...props} products={this.state.products} cartManager={this
                            .state.cartManager}/>)}/>
                    <Route exact path="/catalog"
                           component={(props) => (<Catalog {...props} products={this.state.products} />)}/>
                    <Route exact path="/partners" component={Partners}/>
                    <Route exact path="/services" component={Services}/>
                    <Route exact path="/myaccount"
                        component={(props) => (<MyAccount {...props} loginManager={this.state.loginManager} />)}/>
                    <Route exact path="/thankyou" component={PurchaseComplete}/>
                    <Route exact path="/nocommercialmailsconfirmation" component={NoCommercialMailsConfirmation}/>
                    <Route exact path="/legal" component={Legal}/>
                    <Route exact path="/privacy" component={Privacy}/>
                    
                    <Route exact path="/blog" component={BlogArticlesList}/>
                    <Route exact path="/blog/:postPermalink" component={BlogPost}/>
                    
                    <Route exact path="/contact"
                           component={
                               (props) => (<BaseForm postApiEndpoint="/api/mailing/contactformsubmitted"
                                   useRecaptcha="true"
                                    sendingMessage="Sending your message..."
                                    {...props}>
                                           <ContactForm />
                                       </BaseForm>)}/>

                    <Route exact path="/register"
                           component={
                               (props) => (
                                <BaseForm postApiEndpoint="/api/identity/register"
                                    useRecaptcha="false"
                                    sendingMessage="Creating your account..."
                                    {...props}
                               >
                                            <RegistrationForm />
                            </BaseForm>)}/>

                    <Route exact path="/confirmemail/:id/:code" component={ConfirmEmail}/>

                    <Route exact path="/login"
                           component={
                               (props) => (<BaseForm postApiEndpoint="/api/identity/login"
                                   useRecaptcha="false"
                                    sendingMessage="Logging in..."
                                    {...props} >
                                <LoginForm loginManager={this.state.loginManager} />
                            </BaseForm>)}/>

                    <Route exact path="/forgotpassword"
                           component={
                               (props) => (<BaseForm postApiEndpoint="/api/identity/ForgotPassword"
                                   useRecaptcha="false"
                                   sendingMessage="We are sending you an email..."
                                    {...props} >
                                   <ForgotPasswordForm  />
                            </BaseForm>)}/>

                    <Route exact path="/resetpassword/:id/:token"
                           component={
                               (props) => (<BaseForm postApiEndpoint="/api/identity/resetforgottenpassword"
                                   useRecaptcha="false"
                                   sendingMessage="Resetting your password..."
                                    {...props} >
                                   <ResetPasswordForm {...props} />
                            </BaseForm>)}/>

                    <Route exact path="/logout" component={
                        () => (<Logout
                            loginManager={this.state.loginManager} />)}/>
                </Layout>);
        }
    }
}