import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ReleasesManager } from "../layout/ReleasesManager.js"

import "../../style/myaccount.scss";

class ResellerRegistration extends Component {
    static displayName = ResellerRegistration.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            registerButtonVisible: true,
            keyInputVisible: false,
            confirmationVisible: false,
            key: "",
            confirmationMessage: ""
        };
    }

    async registerProduct() {
        this.setState({ loading: true, keyInputVisible: false });
        const response = await fetch("api/licensing/registerproduct",
            {
                method: "POST",
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    key: this.state.key
                })
            });
        let confirmationMessage = "Error while generating the license.";
        if (response.status === 200) {
            const data = await response.text();
            if (data) {
                confirmationMessage = data.substring(1, data.length - 1);
            }
        }

        this.setState({ loading: false, confirmationVisible: true, confirmationMessage: confirmationMessage });
    }

    handleProductKeyInput(event) {
        this.setState({ key: event.target.value });
    }

    renderRegisterButton() {
        return (
            <Fragment>
                <div>
                    <button className="btn btn-primary btn-soundspear-primary-black btn-register-product"
                            onClick={() => {
                                this.setState({ registerButtonVisible: false, keyInputVisible: true })
                            }}>
                        Register a new product
                    </button>
                    <span className="ml-2 register-btn-description">Click here if you bought a license from a reseller</span>
                </div>
            </Fragment>);
    }

    renderConfirmation() {
        return (<div className="alert alert-danger" role="alert">
                    {this.state.confirmationMessage}
                </div>);
    }

    renderKeyInput() {
        return (
            <Fragment>
                <input type="text" className="form-control myaccount-guid-input" placeholder="Enter your product key"
                       onChange={this.handleProductKeyInput.bind(this)}/>
                <button className="btn btn-primary btn-soundspear-primary"
                        onClick={() => { this.registerProduct() }}>
                    Validate
                </button>
            </Fragment>);
    }

    renderLoadingScreen() {
        return (
            <Fragment>
                <BounceLoader
                    className="mr-2"
                    css={css`margin: auto;`}
                    size={30}
                    color={"#d4af37"}/>
                Registering the product...
            </Fragment>
        );
    }

    render() {
        if (this.state.loading) {
            return this.renderLoadingScreen();
        } else if (this.state.registerButtonVisible) {
            return this.renderRegisterButton();
        } else if (this.state.keyInputVisible) {
            return this.renderKeyInput();
        } else if (this.state.confirmationVisible) {
            return this.renderConfirmation();
        }
        return (<Fragment/>);
    }
}

class LicenseGenerator extends Component {
    static displayName = LicenseGenerator.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            generateButtonVisible: true,
            guidInputVisible: false,
            licenseKeyVisible: false,
            licenseKey: "",
            guid: ""
        };
    }

    async fetchLicense() {
        this.setState({ loading: true, guidInputVisible: false });
        const response = await fetch("api/licensing/generatenewlicense",
            {
                method: "POST",
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    guid: this.state.guid,
                    productUuid: this.props.productUuid
                })
            });
        let license = "Error while generating the license.";
        if (response.status === 200) {
            const data = await response.text();;
            if (data) {
                license = data.substring(1, data.length - 1);
                license = license.slice(0, 6) + "-" + license.slice(6, 19) + "-" + license.slice(19);
            }
        }

        this.setState({ loading: false, licenseKeyVisible: true, licenseKey: license });
    }

    handleGuidInput(event) {
        this.setState({ guid: event.target.value });
    }

    renderGenerateButton() {
        return (<button className="btn btn-primary btn-soundspear-primary"
                        onClick={() => { this.setState({ generateButtonVisible: false, guidInputVisible: true }) }}>
                    Generate a license key
                </button>);
    }

    renderLicenseKey() {
        return (<p>{this.state.licenseKey}</p>);
    }

    renderGuidInput() {
        return (
            <Fragment>
                <div className="">Enter the Computer ID printed in the plugin window</div>
                <input type="text" className="form-control myaccount-guid-input"
                       onChange={this.handleGuidInput.bind(this)}/>
                <button className="btn btn-primary btn-soundspear-primary mt-2"
                        onClick={() => { this.fetchLicense() }}>
                    Validate
                </button>
            </Fragment>);
    }

    renderLoadingScreen() {
        return (
            <Fragment>
                <BounceLoader
                    className="mr-2"
                    css={css`margin: auto;`}
                    size={30}
                    color={"#d4af37"}/>
                Generating...
            </Fragment>
        );
    }

    render() {
        if (this.state.loading) {
            return this.renderLoadingScreen();
        } else if (this.state.generateButtonVisible) {
            return this.renderGenerateButton();
        } else if (this.state.guidInputVisible) {
            return this.renderGuidInput();
        } else if (this.state.licenseKeyVisible) {
            return this.renderLicenseKey();
        }
        return (<Fragment/>);
    }
}

export class MyAccount extends Component {
    static displayName = MyAccount.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            products: [],
            productsDom: [],
            downloadWindowsDisabled: false,
            downloadMacDisabled: false
        };
    }

    async updateOwnedProducts() {
        let ownedProducts = [];
        const resp = await fetch("api/products/owned",
            {
                method: "GET"
            }); 
        if (resp.status === 200) {
            ownedProducts = await resp.json();
        } else {
            this.setState({
                loading: false
            });
            return;
        }

        const dom = ownedProducts.map((op, i) => {

            const availableWindows = op.product.compatibility.toUpperCase().indexOf("WINDOWS") !== -1;
            const availableMac = op.product.compatibility.toUpperCase().indexOf("MAC") !== -1;

            let windowsDownloadDom = <Fragment />;
            let macDownloadDom = <Fragment />;

            if (availableWindows) {
                windowsDownloadDom = (
                    <button className="btn btn-soundspear-secondary"
                            disabled={this.state.downloadWindowsDisabled}
                            onClick={async () => {
                                this.setState({ downloadWindowsDisabled: true });
                                await ReleasesManager.downloadRelease(document, op.product.uuid, "full", "windows");
                                this.setState({ downloadWindowsDisabled: false });
                            }}>
                        <FontAwesomeIcon icon={["fab", "windows"]} size="sm" className="mr-2" />
                        Windows
                    </button>
                );
            }
            if (availableMac) {
                macDownloadDom = (
                    <button className="btn btn-soundspear-secondary ml-2"
                        disabled={this.state.downloadMacDisabled}
                        onClick={async () => {
                            this.setState({ downloadMacDisabled: true });
                            await ReleasesManager.downloadRelease(document, op.product.uuid, "full", "mac");
                            this.setState({ downloadMacDisabled: false });
                        }}>
                        <FontAwesomeIcon icon={["fab", "apple"]} size="sm" className="mr-2" />
                            Mac
                    </button>
                );
            }

            return (
                <tr key={i}>
                    <td>{op.product.name}</td>
                    <td>{new Date(op.transactionDate).toLocaleDateString("en-US")}</td>
                    <td>
                        {windowsDownloadDom}
                        {macDownloadDom}
                    </td>
                    {/*
                    <td>
                        <LicenseGenerator productUuid={op.product.uuid}/>
                    </td>
                    */}
                </tr>
            );
        });

        this.setState({
            products: ownedProducts,
            productsDom: dom,
            loading: false
        });
    }

    componentDidMount() {
        this.updateOwnedProducts();
        document.title = `My Account | Soundspear - High end digital sound processing - VST - AU`;
    }

    renderLoadingScreen(message) {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center col-auto">
                        <h4>{message}</h4>
                        <BounceLoader
                            css={css`
	                            margin: auto;`}
                            size={100}
                            color={"#d4af37"}/>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderProductsList() {
        return (
            <Container>
                <Row className="mt-3">
                    <ResellerRegistration/>
                </Row>
                {/*
                <Row>
                    <button className="btn btn-soundspear-secondary mb-2 ml-2"
                            onClick={() => {
                            window.open('/documents/Activation-Manual.pdf');
                        }}>
                        <FontAwesomeIcon icon={["fas", "file-pdf"]} size="sm" className="mr-2" />
                        How to activate a plugin?
                    </button>
                </Row>
                */}
                <div className="mt-4 text-center">
                    Looking for the license generation? We recently removed all DRMs from our plugins! <br/>
                    You  simply have to download and install the latest version of your plugin.
                </div>
                <table className="table table-striped owned-products-list mt-1">
                    <thead>
                    <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Registration Date</th>
                        <th scope="col">Download Link</th>
                        {/*
                        <th scope="col">License Key</th>
                        */}
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.productsDom}
                    </tbody>
                </table>
            </Container>
        );
    }

    render() {
        let redirectToLoginDom = (<Fragment />);
        if (!this.props.loginManager.isLoggedIn()) {
            return (<Redirect to="/login" />);
        }

        if (this.state.loading) {
            return this.renderLoadingScreen(this.props.sendingMessage);
        } else {
            return this.renderProductsList();
        }
    }
}