import React, { Component, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";

import "../../style/form.scss";

export class ConfirmEmail extends Component {
    static displayName = ConfirmEmail.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            success: false
        };
        console.log(this.props.match.params);
    }

    async checkEmail() {
        const endPoint =
            `/api/identity/confirmemail?userId=${this.props.match.params.id}&code=${this.props.match.params.code}`;
        const response = await fetch(endPoint,
            {
                method: "GET"
            });
        if (response.status === 200) {
            const data = await response.text();
            console.log(data);
            if (data) {
                this.setState({ loading: false, success: false });
            } else {
                this.setState({ loading: false, success: true });
            }
        } else {
            this.setState({ loading: false, success: false });
        }
    }

    renderLoadingScreen() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center col-auto">
                        <h4>Verifying your email</h4>
                        <BounceLoader
                            css={css`
	                            margin: auto;`}
                            size={100}
                            color={"#d4af37"}/>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidMount() {
        document.title = `Email Confirmation | Soundspear - High end digital sound processing - VST - AU`;
        this.checkEmail();
    }

    renderConfirmation() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <h1 className="menu-subtitle">High-end digital sound processing - VST - AU - RTAS</h1>
                    </Col>
                </Row>

                <div className={`alert text-center alert-${this.state.success ? "success" : "danger"}`}
                     role="alert">
                    {this.state.success
                        ? "Your email was confirmed. You can now log in."
                        : "The confirmation link is invalid. Your email was not verified."}
                </div>
            </Container>
        );
    }

    render() {
        if (this.state.loading) {
            return this.renderLoadingScreen();
        } else {
            return this.renderConfirmation();
        }
    }
}