import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function ForgotPasswordForm(props) {
    useEffect(() => {
        document.title = `Reset your password | Soundspear - High end digital sound processing - VST - AU`;
    }, []);
    if (props.formSent) {
        return (
            <div className="alert alert-warning" role="alert">
                If an account exist with this email address, a link to reset your password has been sent.
            </div>);
    } else {
        return (
            <div className="card border-soundspear-black rounded-0">
                <div className="card-header p-0">
                    <div className="bg-black text-soundspear-primary text-center py-2">
                        <h3><FontAwesomeIcon icon={["fas", "envelope"]
} size="sm" className="mt-3"/> Password forgotten</h3>
                        <p className="m-0"></p>
                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="form-group">
                        <label>Enter the email address for your account. We will send you a link to reset your password.</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={[
                                "fas", "envelope"
                            ]} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="email" type="text" className="form-control" placeholder="Your email..." required
                                   onChange={(t) => { props.onFormUpdate(t); }}/>
                        </div>
                    </div>

                    <div className="text-center">
                        <input type="submit" value="Send" className="btn btn-soundspear-primary-black btn-block py-2"/>
                    </div>
                </div>
            </div>
        );
    }
}