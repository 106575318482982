import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function ResetPasswordForm(props) {
    useEffect(() => {
        document.title = `Reset your password | Soundspear - High end digital sound processing - VST - AU`;
    }, []);

    if (props.formSent) {
        return (
            <div className="alert alert-warning" role="alert">
                Your password was successfully reset. You can now login.
            </div>);
    } else {
        return (
            <div className="card border-soundspear-black rounded-0">
                <div className="card-header p-0">
                    <div className="bg-black text-soundspear-primary text-center py-2">
                        <h3><FontAwesomeIcon icon={["fas", "envelope"]
    } size="sm" className="mt-3"/> Reset your password</h3>
                        <p className="m-0"></p>
                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={[
                                    "fas", "key"
                                ]} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="newPassword" type="password" className="form-control" required placeholder="Your new password..." required
                                   onChange={(t) => { props.onFormUpdate(t); }}/>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={[
                                    "fas", "key"
                                ]} size="sm" className="form-icon"/>
                                </div>
                            </div>
                            <input name="confirmNewPassword" type="password" className="form-control" required placeholder="Repeat your new password..."
                                   onChange={(t) => { props.onFormUpdate(t); }}/>
                        </div>
                    </div>

                    <div className="text-center">
                        <input onClick={() => {
                            props.addToForm({
                                tokenEncoded: props.match.params.token,
                                emailEncoded: props.match.params.id
                            });
                        }} type="submit" value="Confirm" className="btn btn-soundspear-primary-black btn-block py-2"/>
                    </div>
                </div>
            </div>
        );
    }
}