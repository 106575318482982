import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../../style/services-partners.scss";

export class Partners extends Component {
    static displayName = Partners.name;

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Partners | Soundspear - High end digital sound processing - VST - AU</title>
                    <meta name="description" content="We are ready to take music software distribution to the next level." />
                </Helmet>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col className="text-center">
                            <h1 className="menu-subtitle">High-end digital sound processing - VST - AU - RTAS</h1>
                        </Col>
                    </Row>

                    <Row className="first-section justify-content-center" id="content-creators">

                        <Col md={{ size: 12 }} lg={{ size: 5 }} className="first-section-description">
                            <h2 className="services-partners-title">
                                Content Creators
                        </h2>
                            <p>
                                <b>We are aware that social influencers are the best collaborators to showcase our expertise to the world.</b>
                            </p>
                            <p>
                                Giving you a reward is our manner to thank you for your valuable content.
                        </p>
                            <p>
                                <b>Your honesty is worth more than anything</b>. An influencer will never be asked to either promote us or to produce biased reviews. Be yourself! Use our plugins the way you want to and produce content that suits you, no matter what you can say.
                        </p>
                            <Link className="btn btn-primary btn-soundspear-primary" to="/contact">Contact Us</Link>

                        </Col>
                        <Col md={{ size: 12 }} lg={{ size: 5 }} className="hidden-sm my-auto">
                            <img className="embed-responsive first-img border-soundspear-primary" src="/images/partners-content-creators.jpg" alt="A camera in front of a desk with a computer" />
                        </Col>
                    </Row>

                    <Row className="second-section justify-content-center" id="resellers">

                        <Col xs={{ size: 12, order: 2 }} lg={{ size: 5, order: 1 }} className="hidden-sm my-auto">
                            <img className="embed-responsive second-img border-soundspear-primary" src="/images/partners-resellers.jpg" alt="A handshake" />
                        </Col>

                        <Col xs={{ size: 12, order: 1 }} lg={{ size: 5, order: 2 }} className="second-section-description">
                            <h2 className="services-partners-title">
                                Resellers
                        </h2>
                            <p>
                                <b>We are ready to take music software distribution to the next level.</b>
                            </p>
                            <p>
                                Soundspear would be glad to hear about your marketplace. We can offer <b>bulk discounts</b> and set-up <b>licensing APIs</b>.
                        </p>
                            <p>
                                There is a variety of reasons for online digital customers to buy their licenses through a distributor: hunting for discounts, obtaining products through a single marketplace... As customers satisfaction is our greatest concern, we want to acknowledge all of those reasons.
                        </p>
                            <Link className="btn btn-primary btn-soundspear-primary" to="/contact">Contact Us</Link>

                        </Col>
                    </Row>
                </Container>
            </Fragment>            
        );
    }
}