import React, { Component } from "react";
import { NavMenu } from "./NavMenu";
import ShoppingCart from "./ShoppingCart";
import { Footer } from "./Footer";
import CookieConsent from "react-cookie-consent";

import { library } from "@fortawesome/fontawesome-svg-core"
import {
    fab,
    faInstagram,
    faYoutube,
    faWindows,
    faApple
} from "@fortawesome/free-brands-svg-icons"
import {
    fas,
    faShoppingCart,
    faCartPlus,
    faFilePdf,
    faEnvelope,
    faUser,
    faComment,
    faKey,
    faTimes,
    faDownload
} from "@fortawesome/free-solid-svg-icons"

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        library.add(fas,
            fab,
            faInstagram,
            faYoutube,
            faWindows,
            faApple,
            faCartPlus,
            faShoppingCart,
            faFilePdf,
            faEnvelope,
            faUser,
            faComment,
            faKey,
            faTimes,
            faDownload);
    }

    render() {
        return (
            <div>
                <NavMenu products={this.props.products}
                         loginManager={this.props.loginManager}/>
                <ShoppingCart cartManager={this.props.cartManager} loginManager={this.props.loginManager}/>
                {this.props.children}
                <CookieConsent
                    buttonText="I accept"
                    style={{ fontVariant: "small-caps", backgroundColor: "black" }}>
                    This website vses cookies to enhance yovr experience.
                </CookieConsent>
                <Footer/>
            </div>
        );
    }
}