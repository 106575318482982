import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../../style/formula.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class Formula extends Component {
    static displayName = Formula.name;

    async download(assetType) {
        let downloadLink = "";
        
        if (assetType === "manual") {
            downloadLink = "https://github.com/soundspear/formula/raw/develop/assets/manual/Formula-User-Manual.pdf";
        }
        else {
            const response = await fetch("https://api.github.com/repos/soundspear/formula/releases",
                {
                    method: "GET"
                });
            if (response.status === 200) {
                const data = JSON.parse(await response.text());
                console.log(data);
                let lastRelease = data.find(rel => !rel.prerelease);
                if (!lastRelease) {
                    lastRelease = data[0];
                }

                if (assetType === "windows") {
                    downloadLink = lastRelease.assets.find(asset => asset.name === "formula-windows.exe").browser_download_url;
                }
                else if (assetType === "macos") {
                    downloadLink = lastRelease.assets.find(asset => asset.name === "formula-macos.zip").browser_download_url;
                }
                else if (assetType === "linux") {
                    downloadLink = lastRelease.assets.find(asset => asset.name === "formula-linux.tgz").browser_download_url;
                }
            }
        }

        const link = document.createElement("a");
        link.href = downloadLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    renderFormulaFree() {
        return (<Fragment>
            <Row className="mt-4" id="free">
                <Col className="text-center">
                    <h1 className="font-weight-bold">Formula</h1>
                    <h4 className="font-weight-light">Creativity unleashed</h4>
                    <div className="mt-3 product-page-compatibility">VST3, AU, Standalone | Windows, macOS, Linux | Free</div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="text-center">
                    <img className="img-fluid formula-demo-img"
                         src="https://github.com/soundspear/formula/raw/develop/assets/demo.webp"
                         alt="Formula"/>
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col className="text-justify" style={{ maxWidth: "660px" }}>
                    <p>Formula is a <b>free and <a href="https://github.com/soundspear/formula" target="_blank" rel="noopener noreferrer">open-source</a></b> integrated development environment to <b>create your own audio effects</b> the simplest way possible.<br/><br/>
                        Formula can either be used as an effect plugin <b>inside your DAW</b> (VST3 or AU),
                        or as a <b>standalone application</b>. The effects within Formula are made using the C programming language.</p>
                    <hr/>
                    <p><b>🎸 Not a programmer?</b> Browse and use <b>hundreds of bundled open-source effects</b> made by developers from around the world.
                    </p>
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col className="text-center"  xs={12} sm={"auto"}>
                    <a className="btn btn-soundspear-primary ml-2 mb-2" onClick={() => this.download("windows")}>
                        <FontAwesomeIcon icon={["fab", "windows"]} size="sm" className="mr-2" />
                        Download (Windows)
                    </a>
                </Col>
                <Col className="text-center" xs={12} sm={"auto"} >
                    <a className="btn btn-soundspear-primary ml-2 mb-2" onClick={() => this.download("macos")}>
                        <FontAwesomeIcon icon={["fab", "apple"]} size="sm" className="mr-2" />
                        Download (macOS)
                    </a>
                </Col>
                <Col className="text-center" xs={12} sm={"auto"} >
                    <a className="btn btn-soundspear-primary ml-2 mb-2" onClick={() => this.download("linux")}>
                        <FontAwesomeIcon icon={["fab", "linux"]} size="sm" className="mr-2" />
                        Download (Linux)
                    </a>
                </Col>
                <Col className="text-center" xs={12} sm={"auto"} >
                    <a className="btn btn-soundspear-secondary ml-2 mb-2" onClick={() => this.download("manual")}>
                        <FontAwesomeIcon icon={["fas", "file-pdf"]} size="sm" className="mr-2" />
                        User manual
                    </a>
                </Col>
            </Row>
        </Fragment>);
    }
    
    renderCorporates() {
        return (<Fragment>
            <Row className="mt-4" id="corporates">
                <Col className="text-center">
                    <h4 className="font-weight-bold">For professionals</h4>

                    <img className="embed-responsive formula-square-logo-img ml-auto mr-auto mt-3 mb-3"
                         src="/images/products/formula/formula-logo-square.jpg" alt="Soundspear Formula Logo"/>
                    
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col className="text-justify" style={{ maxWidth: "660px" }}>
                    <p>Interested in using Formula in your company? Private licensing is available for companies, with the following features:
                    <ul>
                        <li>C++ Support</li>
                        <li>Custom library support</li>
                        <li>Use of custom compilers and build integration</li>
                        <li>Preprocessor, dynamic memory allocation</li>
                        <li>Private tenant to share your codebase among your employees</li>
                    </ul>
                    </p>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col className="text-center">
                    <Link className="btn btn-primary btn-soundspear-primary" to="/contact">Contact Us</Link>
                </Col>
            </Row>
        </Fragment>);
    }
    
    render() {
        return (
            <div className="formula-body">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Formula - VST - VST3 - AU</title>
                    <meta name="description" content="Bundling innovation into premium VST plugins by combining cutting-edge algorithms and traditional hardware emulation" />
                </Helmet>

                <Container className="product-page-container">
                    {this.renderFormulaFree()}
                    <hr/>
                    {this.renderCorporates()}
                </Container>
            </div>
        );
    }
}