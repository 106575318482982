import React, { Component, Fragment } from "react";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from "reactstrap";
import "../../style/navbar.scss";
import { HashLink as Link } from 'react-router-hash-link';

export class NavMenu extends Component {
    constructor (props) {
        super(props);

        this.toggleNavbarMobile = this.toggleNavbarMobile.bind(this);
        this.toggleNavbarDesktop = this.toggleNavbarDesktop.bind(this);
        this.state = {
            collapsed_mobile: true,
            active_desktop_section: null,
            isLoggedIn: props.loginManager.isLoggedIn()
        };
        this.displayName = NavMenu.name;

        props.loginManager.subscribe((authResult) => this.setState({ isLoggedIn: authResult }));
    }

    collapseNavbar() {
        this.setState({
            collapsed_mobile: true,
            active_desktop_section: null
        });
    }

    toggleNavbarMobile () {
        this.setState({
            collapsed_mobile: !this.state.collapsed_mobile,
            active_desktop_section: null
        });
    }

    toggleNavbarDesktop(section) {
        let activeDesktopSection = this.state.active_desktop_section;
        if (activeDesktopSection === section) {
            activeDesktopSection = null;
        } else {
            activeDesktopSection = section;
        }

        this.setState({
            collapsed_mobile: true,
            active_desktop_section: activeDesktopSection
        });
    }

    mapEffectsToCategory(categoryName) {
        return this.props.products.map((p, idx) => {
            if (p[categoryName] && !p.isEndOfLife) {
                return (<NavItem key={idx}>
                            <NavLink onClick={() => this.collapseNavbar()}
                                tag={Link} to={`/product/${p.permalink}`}>
                                {p.name}
                            </NavLink>
                        </NavItem>);
            } else {
                return null;
            }
        });
    }

    render() {

        const traditionalEffects = this.mapEffectsToCategory("isTraditionalEffect");
        const capsuleEffects = this.mapEffectsToCategory("isCapsuleEffect");
        const bundles = this.mapEffectsToCategory("isBundle");

        let userMenuDom;
        if (this.state.isLoggedIn) {
            userMenuDom = (
                <Fragment>
                    <NavItem>
                        <NavLink tag={Link} to="/myaccount"
                            onClick={() => this.collapseNavbar()}>My Account</NavLink>
                   </NavItem>
                   <NavItem>
                        <NavLink tag={Link} to="/logout"
                                 onClick={() => this.collapseNavbar()}>Logout</NavLink>
                   </NavItem>
               </Fragment>);
        } else {
            userMenuDom = (
                <Fragment>
                   <NavItem>
                        <NavLink tag={Link} to="/register"
                                 onClick={() => this.collapseNavbar()}>Register</NavLink>
                   </NavItem>
                   <NavItem>
                        <NavLink tag={Link} to="/login"
                                 onClick={() => this.collapseNavbar()}>Login</NavLink>
                   </NavItem>
               </Fragment>);
        }

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm navbar-dark box-shadow" light>
                    <Container>
                        <Row className="justify-content-center">
                            <Col className="col-auto">
                                <NavbarBrand tag={Link} to="/">
                                    <img src={process.env.PUBLIC_URL + "images/soundspear-logo.png"}
                                         className="navbar-logo" alt="The company logo"/>
                                </NavbarBrand>
                                <NavbarToggler onClick={this.toggleNavbarMobile} className="mr-2"/>
                            </Col>
                        </Row>
                        <Row className="justify-content-center navigation-row">
                            <Col className="col-auto">
                                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state
                                    .collapsed_mobile} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        <NavItem>
                                            <NavLink tag={Link} to="/"
                                                     onClick={() => this.collapseNavbar()}>Home</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} onClick={() => this.toggleNavbarDesktop(
                                                "Collection")}>Collection</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/services"
                                                     onClick={() => this.collapseNavbar()}>Services</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/partners"
                                                     onClick={() => this.collapseNavbar()}>Partners</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/blog"
                                                     onClick={() => this.collapseNavbar()}>Blog</NavLink>
                                        </NavItem>
                                        {userMenuDom}
                                    </ul>
                                </Collapse>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.active_desktop_section === "Collection"}>
                            <Row className="justify-content-center">
                                <Col className="col-auto">
                                    <ul className="navbar-submenu-nav">
                                        <li className="navbar-submenu-title">
                                            <NavLink className="navbar-submenu-title" tag={Link
} to="/catalog?c=effect" onClick={() => this.collapseNavbar()}>Traditional effects</NavLink>
                                        </li>
                                        {traditionalEffects}
                                    </ul>
                                </Col>
                                <Col className="col-auto">
                                    <ul className="navbar-submenu-nav">
                                        <li className="navbar-submenu-title">
                                            <NavLink className="navbar-submenu-title" tag={Link
} to="/catalog?c=capsule" onClick={() => this.collapseNavbar()}>Capsules</NavLink>
                                        </li>
                                        {capsuleEffects}
                                    </ul>
                                </Col>
                                <Col className="col-auto">
                                    <ul className="navbar-submenu-nav">
                                        <li className="navbar-submenu-title">
                                            <NavLink className="navbar-submenu-title">Open source</NavLink>
                                        </li>
                                        <NavItem key="0">
                                            <NavLink onClick={() => this.collapseNavbar()}
                                                     tag={Link} to={`/product/formula`}>
                                                Formula
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </Col>
                                <Col className="col-auto">
                                    <ul className="navbar-submenu-nav">
                                        <li className="navbar-submenu-title">
                                            <NavLink className="navbar-submenu-title" tag={Link
                                            } to="/catalog?c=bundles" onClick={() => this.collapseNavbar()}>Bundles</NavLink>
                                        </li>
                                        {bundles}
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col className="col-auto text-center">
                                    <NavLink className="navbar-submenu-title" tag={Link
} to="/catalog" onClick={() => this.collapseNavbar()}>Full Catalog</NavLink>
                                </Col>
                            </Row>
                        </Collapse>

                    </Container>
                </Navbar>
            </header>
        );
    }
}